<template>
  <a-spin :spinning="state.page_loading">
    <sdPageHeader title="Gelen Fatura Bilgileri">
      <template #buttons>
        <div class="page-header-actions">
          <a-button
            type="primary"
            @click="state.new_incoming_invoice_form.show = true"
          >
            Yeni Kayıt
          </a-button>
        </div>
      </template>
    </sdPageHeader>
    <Main>
      <a-card>
        <a-form
          layout="inline"
          class="text-right"
          style="margin-bottom: 10px"
        >
          <a-form-item>
            <a-input
              v-model:value="state.searchText"
              placeholder="Ara"
              prefix-icon="search"
              @keydown="handleSearchInputKeyDown"
            />
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              @click="loadPageData"
            >
              Ara
            </a-button>
          </a-form-item>
          <a-form-item />
        </a-form>

        <a-table
          :data-source="state.rawItems"
          :columns="state.columns"
          row-key="id"
        >
          <template #serial_number_render="{ record }">
            <router-link :to="{ name: 'IncomingInvoices.Detail.Page', query: { invoice_id: record.id } }">
              {{ record.serial_number }}
            </router-link>
          </template>
          <template #is_checked_render="{ record }">
            <sdFeatherIcons
              v-if="record.is_checked"
              type="check-circle"
            />
            <sdFeatherIcons
              v-else
              type="circle"
            />
          </template>
          <template #settings_render="{ record }">
            <sdFeatherIcons
              type="settings"
              style="cursor:pointer"
              @click="handleSettingsModal(record)"
            />
          </template>
        </a-table>
      </a-card>

      <a-modal
        v-model:visible="state.new_incoming_invoice_form.show"
        title="Yeni Gelen Fatura Yükle"
        :confirm-loading="false"
        ok-text="Kaydet"
        cancel-text="İptal"
        @ok="handleNewIncomingInvoiceFormSubmit"
        @cancel="state.new_incoming_invoice_form.show = false"
      >
        <a-form layout="vertical">
          <a-form-item label="Kargo Firması">
            <a-select
              v-model:value="state.new_incoming_invoice_form.state.cs_company_id"
              style="width: 100%"
            >
              <a-select-option
                v-for="item in state.cs_companies"
                :key="item.id"
              >
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="Fatura Seri No"
            xhelp="AB-00012345 formatında bilgi girişi yapınız."
            :help="state.new_incoming_invoice_form.help.serial_number"
          >
            <a-input
              v-model:value="state.new_incoming_invoice_form.state.serial_number"
            />
          </a-form-item>
          <a-form-item
            label="Fatura Tutarı"
            help="Ondalık kısım için nokta kullanın, ör: 1564.12"
            v-if="[1,3,4].indexOf(state.new_incoming_invoice_form.state.cs_company_id) > - 1"
          >
            <a-input-number
              v-model:value="state.new_incoming_invoice_form.state.invoice_amount"
              style="width: 100%;"
              size="small"
              :precision="2"
              decimal-separator="."
            />
          </a-form-item>
          <a-form-item
              label="Fatura Tarihi"
              v-if="[1,3,4].indexOf(state.new_incoming_invoice_form.state.cs_company_id) > - 1">
            <a-date-picker
              v-model:value="state.new_incoming_invoice_form.state.invoice_date"
              :style="{ width: '100%' }"
              format="DD/MM/YYYY"
            />
          </a-form-item>
          <a-input
            type="file"
            v-if="[1,3,4].indexOf(state.new_incoming_invoice_form.state.cs_company_id) > - 1"
            @change="handleFileChange"
          />
        </a-form>
      </a-modal>

      <a-modal
        v-model:visible="state.edit_settings_form.show"
        title="Bilgileri Düzenle"
        :confirm-loading="state.edit_settings_form.submitting"
        ok-text="Kaydet"
        cancel-text="Kapat"
        @ok="handleUpdateSettings"
        @cancel="state.edit_settings_form.show = false"
      >
        <a-form layout="vertical">
          <a-form-item
            html-for="is_checked"
            label="Onaylandı mı?"
          >
            <a-switch
              id="is_checked"
              v-model:checked="state.edit_settings_form.state.is_checked"
              checked-children="Evet"
              un-checked-children="Hayır"
            />
          </a-form-item>
          <a-form-item
            label="Yönetici Notu"
            html-for="admin_note"
          >
            <a-textarea
              id="admin_note"
              v-model:value="state.edit_settings_form.state.admin_note"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </Main>
  </a-spin>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue'
import { Main } from '@/view/styled'
import restMethods from '@/utility/restMethods'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {
    Main,
  },
  watch: {
    "state.new_incoming_invoice_form.state.cs_company_id" : function(current, old){
      if(current == 3){

      }
      switch (current){
        case 3 :
          this.state.new_incoming_invoice_form.help.serial_number = "AB-00012345 formatında bilgi girişi yapınız.";
          break;
        case 2 :
          this.state.new_incoming_invoice_form.help.serial_number = "AYB2024123123 formatında bilgi girişi yapınız.";
          break;
        default :
          this.state.new_incoming_invoice_form.help.serial_number = "";
          break;
      }
    },
  },
  setup() {

    const state = reactive({
      page_loading: false,

      rawItems: [],
      cs_companies: [],

      selectedFile: [],
      searchText: '',

      new_incoming_invoice_form: {
        show: false,
        help: {
          serial_number: '',
        },
        state: {
          cs_company_id: '',
          serial_number: '',
          invoice_amount: '',
          invoice_date: '',
        },
      },

      edit_settings_form: {
        show: false,
        submitting: false,
        state: {
          id: '',
          is_checked: false,
          admin_note: '',
        },
      },

      columns: [
        {
          title: 'Kargo Firması',
          dataIndex: 'cs_company_title',
          align: 'center',
        },
        {
          title: 'Fatura Tarihi',
          dataIndex: 'invoice_date',
          align: 'center',
        },
        {
          title: 'Fatura Seri No',
          dataIndex: 'serial_number',
          align: 'center',
          slots: {
            customRender: 'serial_number_render',
          },
        },
        {
          title: 'Fatura Tutarı',
          dataIndex: 'total_amount',
          align: 'center',
        },
        {
          title: 'Yüklenme Tarihi',
          dataIndex: 'created_at',
          align: 'center',
        },
        {
          title: 'Onaylandı mı?',
          dataIndex: 'is_checked',
          align: 'center',
          slots: {
            customRender: 'is_checked_render',
          },
        },
        {
          slots: {
            customRender: 'settings_render',
          },
        },
      ],
    })

    const loadPageData = () => {
      state.page_loading = true
      restMethods.postForNoc('/get-incoming-invoices', {
        search_text: state.searchText,
      }).then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()
          state.rawItems = data.items
          state.cs_companies = data.cs_companies
        }
        state.page_loading = false
      })
    }

    const handleSettingsModal = (record) => {
      state.edit_settings_form.state.id = record.id
      state.edit_settings_form.state.is_checked = record.is_checked
      state.edit_settings_form.state.admin_note = record.admin_note

      state.edit_settings_form.show = true
    }

    const handleUpdateSettings = () => {

      state.edit_settings_form.submitting = true
      restMethods.postForNoc('/incoming-invoice-edit-settings-submit', state.edit_settings_form.state).then(response => {
        if ( ! response.hasError()) {
          message.success(response.getData().message)
          loadPageData()
          state.edit_settings_form.show = false
        }
        state.edit_settings_form.submitting = false
      })

    }

    const handleSearchInputKeyDown = (event) => {
      if (event.key === 'Enter') {
        loadPageData()
      }
    }

    const handleFileChange = (event) => {
      state.selectedFile = event.target.files[0]
    }

    const handleNewIncomingInvoiceFormSubmit = () => {

      const formData = new FormData()

      formData.append('cs_company_id', state.new_incoming_invoice_form.state.cs_company_id)
      formData.append('serial_number', state.new_incoming_invoice_form.state.serial_number)
      formData.append('invoice_amount', state.new_incoming_invoice_form.state.invoice_amount)
      formData.append('invoice_date', state.new_incoming_invoice_form.state.invoice_date)
      formData.append('file', state.selectedFile)

      state.page_loading = true

      // restMethods.postForNoc('/new-incoming-invoice-submit', formData).then(response => {
      restMethods.postForNoc('/purchase', formData).then(response => {
        if ( ! response.hasError()) {
          message.success(response.getData().message)

          state.new_incoming_invoice_form.show = false

          state.new_incoming_invoice_form.state.serial_number = ''
          state.new_incoming_invoice_form.state.invoice_amount = ''
          state.new_incoming_invoice_form.state.invoice_date = ''

        }
        state.page_loading = false
      })
    }

    onMounted(() => {

      loadPageData()

    })

    return {

      state,

      loadPageData,
      handleSearchInputKeyDown,
      handleSettingsModal,
      handleNewIncomingInvoiceFormSubmit,
      handleUpdateSettings,
      handleFileChange,

    }

  },
})
</script>